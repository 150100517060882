<template>
  <v-container class="Product_bar">
    <v-row>
      <v-col cols="4">
        <h3 class="System_admin_text_Product ml-2">UPLOADS NEEDED</h3>
      </v-col>

      <v-col cols="12" sm="3">
        <p>
          <span
            ><b>DEAL: </b>
            <span
              ><b>{{ this.currentDeal }}</b></span
            ></span
          >
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-center" style="align-items: center;">
          <b-button-group>
            <!-- <b-button @click="openOffers" class="offers-button"
              >Offers</b-button
            > -->
            <!-- <b-button @click="openTasks" class="tasks-button">Tasks</b-button> -->
            <b-button @click="openWorksheets" class="worksheets-button"
              >WorkSheets</b-button
            >
            <b-button @click="getData()" class="showall-button">
              <template slot="button-content">
                <b-icon icon="arrow-up"></b-icon> Features
              </template>
              Show All</b-button
            >
            <b-button @click="getRequiredData()" class="purple-button"
              >Only Required</b-button
            >
            <b-button @click="getNeededData()" class="uploads-button"
              >Only Needed</b-button
            >
            <b-button @click="openEdit" class="add-button">Add</b-button>
            <b-button @click="downloadDealFiles" class="success-button"
              >Download All</b-button
            >
          </b-button-group>
        </div>
      </v-col>
    </v-row>
    <!-- <v-row>
            <v-col cols="12" md="4" class="pt-0 pb-0">
                <v-text-field
                        label="Search"
                        v-model="searchText"
                        clearable
                        @click:clear="clearSearch"
                        append-icon="mdi-magnify"
                        @keyup.enter="getData"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
                <v-btn outlined rounded @click="getData">Search</v-btn>
            </v-col>
        </v-row> -->

    <v-col cols="12" class="customer-search-table">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1 cursor-pointer"
      >
        <template v-slot:item.deal_folder_id="props">
          <v-edit-dialog
            :return-value.sync="props.item.folder"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.folder }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="User"
                v-model="props.item.deal_folder_id"
                :items="list_deal_folder"
                item-value="id"
                item-text="text"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.file_name="props">
          <v-edit-dialog
            :return-value.sync="props.item.file_name"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.file_name }}
            <template v-slot:input>
              <v-text-field
                class="v-input--hide-details"
                label="File"
                v-model="props.item.file_name"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.permission_level_id="props">
          <v-edit-dialog
            :return-value.sync="props.item.permission_level"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.permission_level }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="Permission Level"
                v-model="props.item.permission_level_id"
                :items="list_level"
                item-value="id"
                item-text="text"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.required="props">
          <v-edit-dialog
            :return-value.sync="props.item.required_yesno"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.required_yesno }}
            <template v-slot:input>
              <v-checkbox
                class="v-input--hide-details"
                label="Required"
                v-model="props.item.required"
              ></v-checkbox>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.uploaded="props">
          <v-edit-dialog
            :return-value.sync="props.item.uploaded_yesno"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.uploaded_yesno }}
            <template v-slot:input>
              <v-checkbox
                class="v-input--hide-details"
                label="Required"
                v-model="props.item.uploaded"
              ></v-checkbox>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.comments="props">
          <v-edit-dialog
            :return-value.sync="props.item.comments"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.comments }}
            <template v-slot:input>
              <v-text-field
                class="v-input--hide-details"
                label="Comments"
                v-model="props.item.comments"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="canput === 'true'"
            color="warning"
            small
            class="mr-5"
            @click="editedItems(item)"
            >fas fa-edit</v-icon
          >
          <v-icon
            v-if="candelete === 'true'"
            color="error"
            small
            @click="deleteItem(item.id)"
            >fas fa-trash-alt</v-icon
          >
        </template>
        <template v-slot:item.uploads="{ item }">
          <v-icon
            v-if="canput === 'true'"
            color="warning"
            small
            class="mr-5"
            @click="editedItems(item)"
            >fas fa-upload</v-icon
          >
        </template>
        <template v-slot:item.downloads="{ item }">
          <v-icon
            v-if="item.file"
            color="success"
            small
            class="mr-5"
            @click="downloadFile(item)"
            >fas fa-download</v-icon
          >
        </template>
      </v-data-table>
    </v-col>

    <v-row>
      <v-col cols="12">
        <!-- <div class="header-add-systemAdmin" style="float: left">
          <b-button class="wizard-button-group" @click="openSendSellerEmails"
            >Send Seller Emails
          </b-button>
        </div> -->
        <div class="header-add-systemAdmin" style="float: right">
          <b-button class="back-button" @click="goBack">Back </b-button>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogEdit" max-width="600" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="closeEdit">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>{{ editedItem.id ? "Update Item" : "Add Item" }}</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-select
                            style="text-align: center"
                            v-model="editedItem.deal_folder_id"
                            label="Folder"
                            :rules="[(v) => !!v || 'This field is required']"
                            :items="list_deal_folder"
                            item-value="id"
                            item-text="text"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-text-field
                            style="text-align: center"
                            v-model="editedItem.file_name"
                            label="File"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-checkbox
                            v-model="editedItem.required"
                            label="Required"
                          >
                            :true-value=true :false-value=false
                          </v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-checkbox
                            v-model="editedItem.inapplicable"
                            label="Not Applicable"
                          >
                            :true-value=true :false-value=false
                          </v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row> </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-0 pb-0"
                          style="margin-center: 92px"
                        >
                          <v-text-field
                            style="text-align: center"
                            v-model="editedItem.comments"
                            label="Description"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn color="primary" dark @click="saveFormData">
                            Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="close">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>
                  {{ "Upload File Selector for " + this.editedItem.folder }}
                </h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>
                      <v-row>
                        <v-col cols="12">
                          <template id="DealFolder">
                            <div>
                              <label><b>Deal Folder</b></label>
                              <b-form-select
                                v-model="editedItem.deal_folder_id"
                                :options="list_deal_folder"
                                class="mb-3"
                                value-field="id"
                                text-field="text"
                                disabled-field="notEnabled"
                              ></b-form-select>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="12">
                          <template id="File Name">
                            <div>
                              <label><b>File Name</b></label>
                              <b-form-input
                                v-model="editedItem.file_name"
                                placeholder="File Name"
                                type="text"
                              ></b-form-input>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="12">
                          <template id="Uploaded File Name">
                            <div>
                              <label><b>Uploaded File Name</b></label>
                              <b-form-input
                                v-model="editedItem.upload_file_name"
                                placeholder="Uploaded File Name"
                                type="text"
                              ></b-form-input>
                            </div>
                          </template>
                        </v-col>
                      </v-row>
                      <v-row> </v-row>
                      <v-row>
                        <v-col cols="12" md="12" class="pt-0 pb-0">
                          <form
                            @submit.prevent="sendFile"
                            enctype="multipart/form-data"
                          >
                            <v-row>
                              <v-col cols="12" md="8">
                                <input
                                  type="file"
                                  ref="file"
                                  @change="updatePreview"
                                  class="file-input"
                                  id="file-input"
                                />
                                <label for="file-input">
                                  <i class="fa fa-folder"></i>
                                  &nbsp;Select a file...
                                </label>
                                <br />
                                <span>
                                  <strong>Chosen file: </strong>
                                  <span id="file-name"></span>
                                </span>
                                <span v-if="file" class="file-name">{{
                                  file.name
                                }}</span>
                              </v-col>
                              <v-col cols="12" md="4">
                                <div class="user-profile">
                                  <v-img
                                    :src="imagePreview"
                                    contain
                                    width="150px"
                                    class="preview-image"
                                    @click="openUpload"
                                  >
                                  </v-img>
                                </div>

                                <div class="field">
                                  <b-button
                                    v-if="file"
                                    class="success-button"
                                    @click="sendFile"
                                  >
                                    <i class="fa fa-upload"></i>
                                    &nbsp;Upload File</b-button
                                  >
                                </div>
                              </v-col>
                            </v-row>
                          </form>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="9">
                          <v-text-field
                            label="Comments"
                            v-model="editedItem.comments"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-checkbox
                            label="Required"
                            v-model="editedItem.required"
                            :true-value="true"
                            :false-value="false"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row> </v-row>
                      <v-row>
                        <v-col cols="4">
                          <template id="Last Upload">
                            <div>
                              <label><b>Last Upload</b></label>
                              <b-form-input
                                v-model="editedItem.last_upload_date"
                                placeholder="Last Upload Date"
                                type="text"
                                readonly
                              ></b-form-input>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="4">
                          <template id="Uploaded By">
                            <div>
                              <label><b>Uploaded By</b></label>
                              <b-form-input
                                v-model="editedItem.uploaded_by"
                                placeholder="Uploaded By"
                                type="text"
                                readonly
                              ></b-form-input>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="4">
                          <template id="Template">
                            <div>
                              <label><b>Template</b></label>
                              <b-form-select
                                v-model="editedItem.template"
                                :options="list_template"
                                class="mb-3"
                                disabled-field="notEnabled"
                              ></b-form-select>
                            </div>
                          </template>
                        </v-col>
                      </v-row>
                      <v-row> </v-row>
                      <v-row>
                        <v-col cols="7">
                          <template id="DateTemplateSaved">
                            <div>
                              <b-form-datepicker
                                id="start-datepicker"
                                v-model="editedItem.date_template_saved"
                                class="mb-2"
                                placeholder="Date Template Saved"
                              ></b-form-datepicker>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="5">
                          <b-form-checkbox
                            label="Not Applicable"
                            v-model="editedItem.inapplicable"
                            value="true"
                            unchecked-value="false"
                          >
                            Not Applicable
                          </b-form-checkbox>
                        </v-col>
                      </v-row>

                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn color="primary" dark @click="saveFormData">
                            Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <div id="modal-yes-no">
      <b-modal
        id="modal-center"
        v-model="dlgModal"
        centered
        :title="errorTitle"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-row class="mb-1 text-center">
          <b-col cols="12"></b-col>
          <b-col>{{ errorText }}</b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-right"></p>
            <b-button-group class="float-right">
              <b-button @click="getSellerList()" class="offers-button"
                >Yes</b-button
              >
              <b-button @click="dlgModal = false" class="back-button"
                >No</b-button
              >
            </b-button-group>

            <!-- <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="sendBrokerEmail"
              >
                Yes
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="dlgError = false"
              >
                No
              </b-button>               -->
          </div>
        </template>
      </b-modal>
    </div>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";
import axios from "axios";
import { saveAs } from "file-saver";

export function base64ToBlob(base64, mimetype, slicesize) {
  if (!window.atob || !window.Uint8Array) {
    // The current browser doesn't have the atob function. Cannot continue
    return null;
  }
  mimetype = mimetype || "";
  slicesize = slicesize || 512;
  var bytechars = atob(base64);
  var bytearrays = [];
  for (var offset = 0; offset < bytechars.length; offset += slicesize) {
    var slice = bytechars.slice(offset, offset + slicesize);
    var bytenums = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      bytenums[i] = slice.charCodeAt(i);
    }
    var bytearray = new Uint8Array(bytenums);
    bytearrays[bytearrays.length] = bytearray;
  }
  return new Blob(bytearrays, { type: mimetype });
}

export default {
  // beforeRouteEnter(to, from, next) {
  //   if (to.params.id) {
  //     next();
  //   } else {
  //     console.log('beforeRouteEnter',to.params.id);
  //     next('/DealsMain');
  //   }
  // },

  created() {
      
    this.checkPermissions();


    this.logActivity("Opened", this.editedItem.deal_id);

    this.usCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    this.getData();


   

    CommonServices.getList("Users")
      .then((res) => {
        console.log("Users", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_user.push({ id: code.id, text: code.full_name })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList(this.componentURL_deal_folders)
      .then((res) => {
        console.log("Deal Folders: ", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_deal_folder.push({ id: code.id, text: code.folder })
        );
        // localStorage.setItem('Deal', res.results[0].deal)
      })
      .catch((err) => {
        console.log(err);
      });

      //this.checkDeal();
  },

  data() {
    return {
      componentURL: `Uploads/?deal_id=${this.$route.params.id}`,
      componentURL_base: `Uploads`,
      componentURL_required: `Uploads/?deal_id=${this.$route.params.id}&required=True`,
      componentURL_needed: `Uploads/?deal_id=${this.$route.params.id}&required=True&uploaded=False&inapplicable=False`,
      componentURL_deal_folders: `DealFolders/?deal_id=${this.$route.params.id}`,

      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'), 
      valid: false,
      dialog: false,
      dlgModal: false,
      dialogDelete: false,
      dialogEdit: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      currentDeal: localStorage.getItem("Deal"),

      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",

      file: "",
      fileURL: "/photos",
      imagePreview: "",

      headers: [
        { text: "ACTIONS", value: "actions", sortable: false },
        {
          text: "FOLDER",
          value: "deal_folder_id",
          width: "15%",
          sortable: true,
        },
        { text: "FILE", value: "file_name", width: "50%", sortable: true },
        { text: "UPLOAD", value: "uploads", sortable: true },
        { text: "DOWNLOAD", value: "downloads", sortable: true },
        { text: "REQUIRED", value: "required", sortable: true },
        { text: "UPLOADED", value: "uploaded", sortable: true },
        { text: "UPLOADED BY", value: "uploaded_by", sortable: true },
        { text: "COMMENTS", value: "comments", sortable: true },
      ],

      items: [],
      sellerItems: [],
      pendingUploads: [],

      list_deal_folder: [],
      list_user: [],
      list_template: [],

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "Uploads",
        action: "",
        deal_id: "",
        json: "",
      },

      editedItem: {
        id: "",
        deal_id: this.$route.params.id,
        deal: "",
        required: true,
        deal_folder_id: "",
        file_name: "",
        upload_file_name: "",
        file: null,
        last_upload_date: this.$moment(String(Date())).format(
          "YYYY-MM-DDThh:mm"
        ),
        completed_date: this.$moment(String(Date())).format("MM/DD/YYYY"),
        date_template_saved: null,
        last_upload_id: localStorage.getItem("userid"),
        comments: "",
        uploaded: true,
        inapplicable: false,
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    checkDeal() {

      if (this.items.length==0){
        this.$router.push(`/DealsMain/`);
      }else{
        console.log('Deal:',this.$route.params.id)
      }
    },

    openSendSellerEmails() {
      this.dlgModal = true;
      this.errorTitle = "Send Seller Emails?";
      this.errorText =
        "This will send an email to all Sellers who have pending uploads.\nContinue?";
      this.headerBgVariant = "success";
      this.footerBgVariant = "success";
    },

    sendSellerEmails() {
      this.sellerItems.forEach((item) => this.sendSellerEmail(item));
      this.dlgModal = false;
    },

    async sendSellerEmail(item) {
      console.log(
        "Sending seller email...",
        item.first_name,
        item.last_name,
        item.email
      );
      await CommonServices.postData("/Twillio/send-email", {
        user_name: `${item.first_name || "New"} ${item.last_name || "User"}`,
        to_email: item.email,
        subject: "PETERSON CLIENT PENDING UPLOADS",
        text_content:
          "Thanks for your recent signup on http://petersonclient.com/",
        html_content:
          "<p>Dear " +
          item.first_name +
          " " +
          item.last_name +
          ",</p>\
          <p>Please find attached the listing of uploads pending on your account.</p>\
          <p><strong>Please login to https://petersonclient.com/ to address these uploads.</strong> </p>",
      });
    },

    getPendingUploads() {
      CommonServices.listWithParams(
        `vwSellerUploadsNeeded/?seller_id=${this.user_id}`
      )
        .then((response) => {
          this.sellerItems = response.results;
          console.log("sellerItems:", this.sellerItems);
          this.sendSellerEmails();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error retrieving seller list... ";
          console.log(error);
        });
    },
    exportSellerUploadsToExcel() {
      var reportWS = XLSX.utils.json_to_sheet(this.pendingUploads);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, reportWS, "Pending Uploads"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Pending Uploads.xlsx");
    },

    getSellerList() {
      CommonServices.listWithParams("vwSellersMissingUploads")
        .then((response) => {
          this.sellerItems = response.results;
          console.log("sellerItems:", this.sellerItems);
          this.sendSellerEmails();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error retrieving seller list... ";
          console.log(error);
        });
    },

    async downloadDealFiles() {
      let response = await CommonServices.getItem(
        `${process.env.VUE_APP_API_URL}Uploads/download-all-from-deal?deal_id=${this.$route.params.id}`
      );
      let responseData = JSON.parse(response);
      let blob = base64ToBlob(
        responseData.download.data,
        responseData.download.mimetype
      );
      saveAs(blob, responseData.download.filename);
    },

    downloadFile(item) {
      console.log("URL: ", item.file, "Filename: ", item.upload_file_name);
      CommonServices.downloadFile(item.file, item.file_name);
    },

    checkPermissions() {
      if (this.role >2) this.$router.push("/");
    },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    openEdit() {
      this.editedItem.length = 0;
      this.resetItems();
      this.dialogEdit = true;
    },

    resetItems() {
      this.editedItem = {
        id: "",
        deal_id: this.$route.params.id,
        deal: "",
        required: true,
        deal_folder_id: "",
        file_name: "",
        upload_file_name: "",
        file: null,
        last_upload_date: this.$moment(String(Date())).format(
          "YYYY-MM-DDThh:mm"
        ),
        completed_date: this.$moment(String(Date())).format("MM/DD/YYYY"),
        date_template_saved: null,
        last_upload_id: localStorage.getItem("userid"),
        comments: "",
        uploaded: true,
        inapplicable: false,
      };
    },

    openTasks() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openOffers() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openBlindAd() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/BlindAdWorksheet/${this.$route.params.id}`);
    },

    openWorksheets() {
      localStorage.setItem("DealID", this.$route.params.id);
      this.$router.push(`/WorkSheets/`);
    },

    displayFile(item) {
      console.log("Title: ", item.title);
      this.editedItem.file_name = item.title;
      this.editedItem.comments = item.description;
    },

    selectFile() {
      this.file = this.$refs.file.files[0];
      console.log("File: ", this.file);
      this.updatePreview();
    },

    async sendFile() {
      if (!this.editedItem.completed_date) {
        this.editedItem.completed_date = "";
      }
      this.editedItem.last_upload_date = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );

      console.log("Completed Date: ", this.editedItem.completed_date);
      console.log("Upload Date: ", this.editedItem.last_upload_date);
      const formData = new FormData();
      if (this.file) {
        formData.append("file", this.file);
      }
      formData.append("comments", this.editedItem.comments);
      formData.append("file_name", this.editedItem.file_name);
      formData.append("upload_file_name", this.file.name);
      formData.append("deal_id", this.editedItem.deal_id);
      formData.append("deal_folder_id", this.editedItem.deal_folder_id);
      formData.append("last_upload_id", localStorage.getItem("userid"));
      formData.append("uploaded", true);
      formData.append("required", this.editedItem.required);
      formData.append("inapplicable", this.editedItem.inapplicable);
      formData.append("completed_date", this.editedItem.completed_date);
      formData.append("last_upload_date", this.editedItem.last_upload_date);
      try {
        await axios.post("/Uploads/", formData).then((res) => {
          console.log("Post File Response: ", res);
          console.log("Post File: ", res.data.file);
          this.fileURL = res.data.file;
          this.deleteItemSilently();
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "File successfully updated";
          this.dialog = false;
          this.logActivity(
            "Uploaded File",
            this.editedItem.deal_id,
            JSON.stringify(this.editedItem)
          );
        });
      } catch (error) {
        console.log("Post File Error: ", error);
        this.snackbar = true;
        this.snackColor = "error";
        try {
          Object.keys(error.response.data).forEach((key) => {
            this.snackText = error.response.data[key][0];
          });
        } catch (e) {
          this.snackText = "Something went wrong";
        }
      }
    },

    openUpload() {
      document.getElementById("file-input").click();
    },

    updatePreview(e) {
      console.log("e", e);
      var reader,
        files = e.target.files;

      if (files.length === 0) {
        console.log("Empty");
      }

      reader = new FileReader();

      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };

      reader.readAsDataURL(files[0]);

      this.file = this.$refs.file.files[0];
      console.log("File: ", this.file);
      //this.editedItem.file_name=this.file.name;
      //console.log('editedItem: ', this.editedItem)
    },
    getRequiredData() {
      CommonServices.listWithParams(this.componentURL_required, this.items)
        .then((response) => {
          this.items = response.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getNeededData() {
      CommonServices.listWithParams(this.componentURL_needed, this.items)
        .then((response) => {
          this.items = response.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDealFolders() {
      CommonServices.getList(
        `DealFolders/?deal_id=${localStorage.getItem("DealID")}`
      )
        .then((res) => {
          console.log("Deal Folders: ", res);
          let codes = res.results;
          codes.map((code) =>
            this.list_deal_folder.push({ id: code.id, text: code.folder })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.items);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "User Folder Permissions"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Folder Permissions.xlsx");
      this.logActivity(
        "Exported Data to Excel",
        this.editedItem.deal_id,
        JSON.stringify(this.items)
      );
    },

    saveEditDialog(item) {
      CommonServices.updateData(this.componentURL, item).then((response) => {
        const index = this.items.findIndex((item) => item.id === response.id);
        this.$set(this.items, index, response);
      });
      this.snackbar = true;
      this.snackColor = "success";
      this.snackText = "Record successfully updated";
    },

    saveFormData() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.file) {
          this.editedItem.file = null;
        }
        if (this.editedItem.last_upload_date) {
          this.editedItem.last_upload_date = this.$moment(
            String(this.editedItem.last_upload_date)
          ).format("YYYY-MM-DDThh:mm");
        } else {
          this.editedItem.last_upload_date = this.$moment(
            String(Date())
          ).format("YYYY-MM-DDThh:mm");
        }
        if (!this.editedItem.completed_date) {
          this.editedItem.completed_date = null;
        }
        if (this.editedItem.date_template_saved) {
          this.editedItem.date_template_saved = this.$moment(
            String(this.editedItem.date_template_saved)
          ).format("YYYY-MM-DDThh:mm");
        }

        if (this.editedItem.id) {
          CommonServices.updateData(this.componentURL_base, this.editedItem)
            .then((response) => {
              const index = this.items.findIndex(
                (item) => item.id === response.id
              );
              this.$set(this.items, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
              this.logActivity(
                "Updated Form Data",
                this.editedItem.deal_id,
                JSON.stringify(this.editedItem)
              );
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        } else {
          CommonServices.addRecord(this.componentURL, this.editedItem)
            .then((response) => {
              this.items.push(response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.logActivity(
                "Added Record",
                this.editedItem.deal_id,
                JSON.stringify(this.editedItem)
              );
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        }
        this.close();
        this.closeEdit();
      }
    },

    async getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      await CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.items = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
        this.checkDeal();
    },

    deleteItem(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      CommonServices.deleteRecord(this.componentURL_base, this.deletedId)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          this.logActivity(
            "Deleted Record",
            this.editedItem.deal_id,
            JSON.stringify(this.editedItem)
          );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    deleteItemSilently() {
      CommonServices.deleteRecord(this.componentURL_base, this.editedItem.id)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.getData();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    editedItems(item) {
      this.editedItem = Object.assign({}, item);
      localStorage.setItem("DealID", item.deal_id);
      console.log("Deal ID:", localStorage.getItem("DealID"));
      this.getDealFolders(localStorage.getItem("DealID"));
      this.dialog = true;
    },

    clearSearch() {
      this.searchText = "";
      this.getData();
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.$refs.form.reset();
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
button {
  color: white;
  border-radius: 2px;
  cursor: pointer;
  font-size: 13px;
}
.close-popup .v-image__image {
  width: 20px !important;
  object-fit: contain !important;
  background-size: contain !important;
}
.popup-wrap {
  padding: 40px 0;
}

.popup-title {
  padding: 0 50px;
  text-align: center;
}

.popup-title h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}

.close-popup.v-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0 !important;
}

.close-popup.v-btn > span + span {
  display: none !important;
}

.close-popup.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 !important;
  height: auto;
  width: auto;
  min-width: auto;
}

.close-popup.v-btn:before {
  content: none;
}

.offers-button {
  background-color: rgba(145, 9, 31, 255);
  margin-left: 0px;
  margin-right: 8px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.success-button {
  background-color: rgb(11, 83, 11);
  margin-left: 0px;
  margin-right: 8px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.tasks-button {
  background-color: rgba(160, 152, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.uploads-button {
  background-color: rgba(81, 109, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.add-button {
  background-color: rgba(194, 86, 47, 255);
  border-color: rgba(81, 109, 131, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.back-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.worksheets-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  width: 130px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.showall-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  color: black;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.completed-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}

.incomplete-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}
.purple-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.orange-button {
  background-color: rgba(194, 86, 47, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}

.biege-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blah-button {
  background-color: rgba(160, 152, 131, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

input#file-input {
  display: none;
}

.headline {
  background-color: red;
  color: white;
}

input#file-input + label {
  background-color: #0157a0;
  padding: 4px;
  color: #fff;
  border: 2px solid #85bdee;
  border-radius: 9px;
}

input#file-input + label:hover {
  background-color: #20e0a5;
  border-color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}
</style>
